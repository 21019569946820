import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

export type MarketplaceLeadModal = {
	email?: string
	phone?: string
	zip?: string
}

const contextName = 'CONTACT_INPUT_CTX'

export function setMarketplaceContactInputState(
	data?: MarketplaceLeadModal
): Writable<MarketplaceLeadModal> {
	const state = writable<MarketplaceLeadModal>({
		...(data && { ...data })
	})
	setContext(contextName, state)
	return state
}

export function getMarketplaceContactInputState(): Writable<MarketplaceLeadModal> {
	return getContext<Writable<MarketplaceLeadModal>>(contextName)
}
