import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'
import type {
	WaPackageDto,
	HvacFromQueryResponse,
	WoltAdvisorFromQueryRequest,
	FveVariant,
	WoltAdvisorFveResponse,
	WoltAdvisorFveRequest
} from '$lib/types/woltadvisor'

export type MarketplaceLeadModal = {
	open: boolean
	type: 'DETAIL' | 'EXPERT_CONTACT'
	step: 'FORM' | 'SELF_SERVICE'
	leadSource?: string
	leadUid?: string
	czaToken?: string
	lastOtpSentAt?: number
	blurPrice?: boolean
	userInput?: {
		email?: string
		phone?: string
		zip?: string
	}
	marketplaceInput?: {
		formType?: 'hvac' | 'pv'
		catalogPackageIds?: string[]
		email?: string
		phone?: string
		zip?: string
		personalMeeting?: boolean
		installationProductCategory?: 'TEPELNE_CERPADLO' | 'FOTOVOLTAIKA'
		packageSetData?: string
		packageCalculatedFor?: string
		quickInstall?: boolean
	}
	packageData?: {
		fullpackageHvac?: {
			advisor: HvacFromQueryResponse
			initialData: WoltAdvisorFromQueryRequest
			package: WaPackageDto
		}
		fullpackagePv?: {
			advisor: WoltAdvisorFveResponse
			initialData: WoltAdvisorFveRequest
			package: FveVariant
		}
	}
}

const defaults: MarketplaceLeadModal = {
	open: false,
	step: 'FORM',
	type: 'DETAIL'
}

const contextName = 'LEAVE_CONTACT_MODAL_CTX'

export function setMarketplaceLeadModalState(
	data?: MarketplaceLeadModal
): Writable<MarketplaceLeadModal> {
	const state = writable<MarketplaceLeadModal>({
		...defaults,
		...(data && { ...data })
	})
	setContext(contextName, state)
	return state
}

export function getMarketplaceLeadModalState(): Writable<MarketplaceLeadModal> {
	return getContext<Writable<MarketplaceLeadModal>>(contextName)
}
